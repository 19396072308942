module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reonic — Homepage","short_name":"Reonic","description":"Wir machen Sie fit für die Energiewende.","lang":"de","display":"standalone","icon":"src/images/logo-512x512.png","start_url":"/de-de/","localize":[{"start_url":"/de-de/","lang":"de","name":"Reonic — Homepage","short_name":"Reonic","description":"Wir machen Sie fit für die Energiewende."},{"start_url":"/en-gb/","lang":"en","name":"Reonic — Homepage","short_name":"Reonic","description":"We make you ready for the energy transition"},{"start_url":"/pl-pl/","lang":"pl","name":"Reonic — Homepage","short_name":"Reonic","description":"Przygotowujemy do transformacji energetycznej."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af0c391b15a5d25f0c84d8475161291b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
